const R = require("ramda");

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.shouldUpdateScroll = (apiCallbackContext, pluginOptions) => {
  const fromMapPage = R.compose(
    R.test(/\/map/),
    R.path(["prevRouterProps", "location", "pathname"]),
  )(apiCallbackContext);

  const toMapPage = R.compose(
    R.test(/\/map/),
    R.path(["routerProps", "location", "pathname"]),
  )(apiCallbackContext);

  // Prevent gatsby from scrolling to the top of the page when navigating on the
  // /map page
  if (fromMapPage && toMapPage) {
    return false;
  }

  return true;
};
