// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-data-explorer-jsx": () => import("./../../../src/pages/data-explorer.jsx" /* webpackChunkName: "component---src-pages-data-explorer-jsx" */),
  "component---src-pages-department-[url]-jsx": () => import("./../../../src/pages/department/[url].jsx" /* webpackChunkName: "component---src-pages-department-[url]-jsx" */),
  "component---src-pages-department-contentful-department-page-url-jsx": () => import("./../../../src/pages/department/{contentfulDepartmentPage.url}.jsx" /* webpackChunkName: "component---src-pages-department-contentful-department-page-url-jsx" */),
  "component---src-pages-help-jsx": () => import("./../../../src/pages/help.jsx" /* webpackChunkName: "component---src-pages-help-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-map-jsx": () => import("./../../../src/pages/map.jsx" /* webpackChunkName: "component---src-pages-map-jsx" */),
  "component---src-pages-our-story-jsx": () => import("./../../../src/pages/our-story.jsx" /* webpackChunkName: "component---src-pages-our-story-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */)
}

